<template>
  <div class="nextwork" v-if="next">
    <!-- {{ this.$workslist[next] }} -->
    <router-link :to="this.$workslist[next].path" class="nextlink">
      <div class="next_text">
        <p>Next</p>
        <h3>{{ this.$workslist[next].name }}</h3>
      </div>
      <div class="next_thumbnail">
        <img
          :src="'.' + $workslist[next].imgpath"
          :alt="$workslist[next].name"
        />
      </div>
      <svg
        version="1.1"
        id="arrow"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
      >
        <polygon
          class="st0"
          points="0,30 0,1.8 3.1,1.8 3.1,24.7 27.8,0 30,2.2 5.3,26.9 28.2,26.9 28.2,30 "
        />
      </svg>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      arr: Array,
    };
  },
  computed: {
    next() {
      let list = this.$workslist;
      let keys = Object.keys(list);
      let num = keys.indexOf(this.id);
      if (num >= 0) {
        return keys[num + 1];
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nextwork {
  padding: $vt_padding $hr_padding;
  margin: 0 auto;
  max-width: 1280px;
  position: relative;

  a {
    display: block;
    background: $color_light;
    position: relative;
    z-index: 0;
    border-radius: 1rem;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $fontcolor_sub;
      top: 40%;
      left: 0;
      z-index: -1;
      transform: scaleX(200%);
      transform-origin: top;
      opacity: 0.08;
    }

    &:hover {
      .next_thumbnail {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  #arrow {
    font-size: clamp(2rem, 3vw, 3rem);
    fill: $fontcolor;
    position: absolute;
    top: 0;
    right: 0;
    width: clamp(0.35em, 6vw, 0.75em);
    height: auto;
    margin: $hr_padding;
    fill: white;
    transform: rotate(180deg);
  }
  .next_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    border-radius: 1rem;
    color: white;
    h3 {
      font-family: $headline;
      font-weight: 600;
      font-size: clamp(2rem, 6vw, 6rem);
      line-height: 0.8em;
      font-weight: bold;
      transition: 0.5s $easing;
    }
    p {
      font-size: clamp(1rem, 3vw, 2rem);
      font-weight: bold;
      margin-bottom: 0.5em;
      position: absolute;
      top: 0;
      left: 0;
      padding: $hr_padding;
    }
  }
  .next_thumbnail {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;

    border-radius: 1rem;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color_light;
      border-radius: 1rem;
      transition: 0.5s $easing;
      z-index: -100;
      background-image: url("~@/assets/whitenoise.gif");
      opacity: 0.08;
      z-index: 1;
    }
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 0.5s $easing;
    filter: brightness(0.4);
    border-radius: 1rem;
  }
}
</style>
