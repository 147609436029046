<template>
  <div class="navigation_works_list">
    <div class="head">
      <h3>ALL WORKS</h3>
      <h4>Quick Access</h4>
    </div>
    <div class="item" v-for="(data, index) in workslist" :key="index">
      <router-link :to="data.path" class="caption" @click="CloseParent()">
        <p class="year">{{ data.year }}</p>
        <h3 class="title">{{ data.name }}</h3>
        <h4 class="category">{{ data.category }}</h4>
      </router-link>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.navigation_works_list {
  padding: $vt_padding 0;
  padding-top: calc($top_padding * 0.75);
  padding-bottom: $top_padding;
  display: grid;
  grid-gap: 1rem;
  word-break: break-word;
  overflow-wrap: break-word;
  .head {
    h3 {
      font-family: $headline;
      font-weight: bold;
      font-size: 1.5rem;
    }
    h4 {
      font-weight: bold;
    }
  }
  .item {
    width: 100%;
    border-bottom: 1px solid white;
    .year {
      margin-bottom: 1em;
      font-size: 1rem;
      font-weight: bold;
      @include sp {
        font-size: 0.8em;
        margin-bottom: 0.5em;
      }
    }
    .title {
      font-family: $headline;
      font-weight: 600;
      font-size: clamp(1.5rem, 2vw, 2rem);
      line-height: 0.8em;
      font-weight: bold;
      transition: 0.5s $easing;
      @include sp {
        margin-bottom: -0.5rem;
        padding-right: 1.5em;
      }
    }
    .category {
      font-size: 0.8rem;
      margin-top: 1em;
      margin-bottom: 1rem;
      word-break: break-all;
      font-weight: bold;
      word-break: break-word;
      overflow-wrap: break-word;
    }
  }
}
</style>
<script>
export default {
  data() {
    return {
      open: false,
      workslist: this.$workslist,
      test: "test",
    };
  },
  emits: ["open"],
  methods: {
    CloseParent: function () {
      this.$emit("open", this.open);
      console.log(this.open);
    },
  },
};
</script>
