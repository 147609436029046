<template>
  <header v-bind:class="{ 'col-invert': open }">
    <h1 v-on:click="closeNavigation">
      <router-link to="/">
        <img
          src="~@/assets/ico/ico_logo.svg"
          alt="天草のデザイン制作依頼なら。WITCHKRAFT GRAPHIC DESIGN"
        />
      </router-link>
    </h1>
    <h2>
      <img
        class="title1"
        src="~@/assets/ico/logo_wkgd.svg"
        alt="WITCHKRAFT GRAPHIC DESIGN"
      />
      <img
        class="title2"
        src="~@/assets/ico/logo_text.svg"
        alt="WITCHKRAFT GRAPHIC DESIGN"
      />
    </h2>
    <p>Based In Amakusa.</p>
  </header>
  <Navigation
    v-on:open="changeOpenParent"
    v-bind:openstate="this.open"
  ></Navigation>
</template>
<script>
import gsap from "gsap";
import Navigation from "../components/Part_Navigation.vue";
export default {
  data() {
    return {
      open: false,
    };
  },
  props: { openstate: { type: Boolean } },

  mounted() {
    this.titleAnimation();
  },
  methods: {
    closeNavigation() {
      this.open = false;
    },
    changeOpenParent(open) {
      this.open = open;
    },
    titleAnimation() {
      gsap
        .timeline({ repeat: -1 })
        .fromTo(
          ".title1",
          { yPercent: 100, opacity: 0 },
          { yPercent: 0, opacity: 1 }
        )
        .to(".title1", { yPercent: -100, opacity: 0, delay: 5 })
        .fromTo(
          ".title2",
          { yPercent: 100, opacity: 0 },
          { yPercent: 0, opacity: 1 }
        )
        .to(".title2", { yPercent: -100, opacity: 0, delay: 5 });
    },
  },
  components: {
    Navigation,
  },
};
</script>

<style scoped lang="scss">
header {
  width: 100%;
  height: 80px;
  padding: 10px $hr_padding;
  display: inline-flex;
  align-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  &.col-invert {
    filter: invert(1);
  }
  h1,
  h2 {
    display: inline-block;
    width: 5rem;
    img {
      @include img100p;
      object-fit: contain;
    }
  }
  h1 {
    height: 60px;
    width: auto;
    margin-right: 1em;
    flex-shrink: 0;
  }
  h2 {
    overflow: hidden;
    position: relative;
    height: 40%;
    width: 120px;
    flex-shrink: 0;
    margin-right: 1rem;
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: contain;
      opacity: 0;
    }
  }
  p {
    font-size: 0.8rem;
  }
}
</style>
