<template>
  <Header></Header>
  <ContactForm></ContactForm>
  <main id="wrapper">
    <router-view v-slot="{ Component, route }">
      <transition name="route" mode="out-in" appear>
        <div :key="route.path" v-show="loadstate">
          <component :is="Component" :key="route.path"></component>
        </div>
      </transition>
    </router-view>
  </main>
</template>

<script>
import Header from "./components/Part_Header.vue";
import ContactForm from "./components/ContactForm.vue";
export default {
  data() {
    return {
      loadstate: true,
    };
  },
  components: {
    Header,
    ContactForm,
  },
  methods: {
    LoadCompleted(emitload) {
      this.loadstate = emitload;
    },
  },
};
</script>
<style lang="scss">
#background {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  .noise {
    width: 100%;
    height: 100%;
    background-image: url("@/assets/fuzzy-fuzz.gif");
    opacity: 0.03;
  }
}
main {
  min-height: 100vh;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.route-enter-from {
  opacity: 0;
  transform: skewY(-2deg);
  filter: blur(20px);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.5s $easing;
}
.route-leave-to {
  opacity: 0;
  transform: skewY(2deg);
  filter: blur(20px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
