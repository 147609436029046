import { createApp } from "vue";
import App from "./App.vue";
import router from "./route";
import VueAxios from "vue-axios";
import axios from "axios";
import VueGtag from "vue-gtag";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "@/js/typekit.js";
import worklist from "./js/worklist";

const workscnt = Object.keys(worklist).length;

gsap.registerPlugin(ScrollTrigger);

const app = createApp(App);
app.use(VueAxios, axios);
app.use(VueGtag, { config: { id: "G-87LQB1B748" } });
app.use(router);
app.config.globalProperties.$workslist = worklist;
app.config.globalProperties.$workscnt = workscnt;
app.mount("#app");
