<template>
  <footer>
    <div class="inner">
      <div class="col-title">
        <h2>
          <img
            src="~@/assets/ico/ico_logo.svg"
            alt="WITCHKRAFT GRAPHIC DESIGN"
          />
        </h2>
        <h3>WITCHKRAFT GRAPHIC DESIGN</h3>
        <p>
          WITCHKRAFT GRAPHIC DESIGN is a design office by Yuichiro Osako, based
          in Amakusa City, Kumamoto Pref, JAPAN.
        </p>
        <p>
          ウィッチクラフト
          グラフィックデザインは、熊本県天草市を拠点に活動するデザイナー、大迫雄一朗によるデザインオフィスです。
        </p>
      </div>
      <div class="col-social">
        <ul>
          <li>
            <a
              href="https://www.instagram.com/witchkraftgraphicdesign/"
              target="blank"
              rel="noopener"
              class="instagram"
              >instagram</a
            >
          </li>
          <li>
            <a
              href="https://www.facebook.com/WITCHKRAFT0035"
              target="blank"
              rel="noopener"
              class="facebook"
              >facebook</a
            >
          </li>
        </ul>
      </div>
    </div>
    <p class="copyright">
      ©2022<span>WITCHKRAFT GRAPHIC DESIGN</span>
      <a class="link" href="mailto:mail@witchkraft0035.com"
        >mail@witchkraft0035.com</a
      >
      <router-link class="link" to="/privacy-policy">
        プライバシーポリシー</router-link
      >
    </p>
  </footer>
</template>

<style scoped lang="scss">
footer {
  padding: $vt_padding $hr_padding;
  padding-bottom: $top_padding;
  position: relative;
  background-color: white;
  z-index: 0;

  @keyframes bloomMove {
    0% {
      transform: rotate(0deg) scale(1);
      opacity: 0.5;
    }
    50% {
      transform: rotate(180deg) scale(0.8);
      opacity: 1;
    }
    100% {
      transform: rotate(360deg) scale(1);
      opacity: 0.5;
    }
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    animation: bloomMove 8s linear infinite;
    will-change: transform;
  }

  &::before {
    width: 60vmax;
    height: 60vmax;
    right: 0;
    bottom: -30vmax;
    background: radial-gradient(
      circle,
      rgba(160, 150, 200, 0.5) 0%,
      rgba(170, 160, 240, 0.5) 20%,
      transparent 50%
    );
    transform-origin: 45% 35%;
    animation-delay: 1s;
  }
  &::after {
    width: 80vmax;
    height: 80vmax;
    left: 0;
    bottom: -20vmax;
    background: radial-gradient(
      circle,
      rgba(255, 255, 180, 0.8) 0%,
      rgba(255, 255, 200, 0.8) 30%,
      transparent 50%
    );
    transform-origin: 60% 70%;
  }
  .inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;

    .col-title {
      h2 {
        width: 5rem;
        height: 5rem;
        margin-bottom: 1rem;
        img {
          @include img100p;
        }
      }
      h3 {
        font-size: 1.5em;
        font-weight: 600;
        margin-bottom: 1em;
      }
      p {
        font-weight: bold;
        font-size: 0.8rem;
        margin-top: 1em;
        line-height: 1.5em;
      }
    }

    .col-social {
      li {
        margin-bottom: 1em;
        display: inline-block;
        margin-right: 1em;
        font-weight: bold;
      }
      a {
        padding-left: 1.5em;
        position: relative;
        display: inline-flex;

        &::before {
          content: "";
          width: 1em;
          height: 1em;
          left: 0;
          top: calc(50% - 0.4em);
          position: absolute;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &.facebook::before {
          background-image: url("~@/assets/ico/ico_fb.svg");
        }
        &.instagram::before {
          background-image: url("~@/assets/ico/ico_ig.svg");
        }
      }
    }
  }
  .copyright {
    font-size: 0.8rem;
    font-weight: bold;
    display: inline-block;
    width: 100%;
    span {
      display: inline-block;
      margin-right: 1em;
    }
    .link {
      font-weight: normal;
      display: inline-block;
      color: $linkcolor_sub;
      text-decoration: underline;
      font-size: 0.8rem;
      margin-right: 1em;
    }
  }
}
</style>
<script>
export default {
  components: {},
};
</script>
