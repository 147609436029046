//
<template>
  <div
    id="formoverlay"
    v-bind:class="{ 'is-active': formopen }"
    v-on:click="formopen = false"
  ></div>
  <div
    id="message_btn"
    v-bind:class="{ 'is-active': formopen }"
    v-on:click="formopen = !formopen"
  >
    <img src="@/assets/ico/ico_mail_wh.svg" alt="Send a message." />
  </div>
  <div id="formarea" v-bind:class="{ 'is-active': formopen }">
    <div class="message_header">
      <button class="cancel_btn" v-on:click="formopen = !formopen">
        閉じる
      </button>
      <h4>メッセージを作成</h4>
    </div>
    <Form @formopenstate="changeformclose"></Form>
  </div>
</template>
<style lang="scss" scoped>
#formoverlay {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  opacity: 0.9;
  transition: background-color 0.2s;

  &.is-active {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
  }
}
#message_btn {
  transition: 0.2s;
  position: fixed;
  bottom: 3em;
  right: $hr_padding;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: $color_blue;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 60%;
    height: 60%;
  }
  &.is-active {
    pointer-events: none;
  }
}

#formarea {
  position: fixed;
  z-index: 9999;
  right: 0;
  bottom: 0;
  background-color: white;
  margin-right: 2.5vw;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
  width: 37.5vw;
  min-width: 460px;
  transform: translateY(calc(100%));
  transition: 0.2s $easing;
  &:hover {
    .message_btn {
      height: 4rem;
    }
  }
  &.is-active {
    transform: none;
  }
  @include sp {
    width: 95vw;
    min-width: 0px;
  }
  .message_header {
    font-weight: bold;
    text-align: center;
    width: 100%;
    height: 4rem;
    align-items: center;
    border-bottom: 1px solid $color_sub;
    position: relative;
    .cancel_btn {
      color: $color_blue;
      line-height: 1rem;
      padding: 1.5rem 1rem;
      right: 0;
      top: 0;
      position: absolute;
      @include sp {
        left: 0;
        right: auto;
      }
    }
    h4 {
      color: $color_line;
      padding: 1.5rem 0;
      line-height: 1rem;
    }
  }
}

.deletearea {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}
</style>
<script>
import Form from "@/components/Part_Form.vue";

export default {
  data() {
    return {
      formopen: false,
    };
  },
  components: {
    Form,
  },
  methods: {
    changeformclose(state) {
      this.formopen = state;
    },
  },
};
</script>
