//
<template>
  <div class="message_contents">
    <div class="loader_wrap" v-bind:class="{ 'is-active': sending }">
      <div class="loader"></div>
    </div>
    <div v-if="!complete">
      <dl>
        <dt class="label">メールアドレス<span class="require">必須</span></dt>
        <dd>
          <div class="inputbox" v-bind:class="{ deletearea: inputdata }">
            <input
              type="text"
              name="namedata"
              placeholder="mail@witchkraft0035.com"
              class="inputText mailarea"
              v-model="mailarea"
            />
          </div>
          <div class="phase_check_mail" v-bind:class="{ deletearea: makesure }">
            {{ mailarea }}
          </div>
          <p class="errorbox">{{ mailmiss }}</p>
        </dd>
      </dl>
      <dl>
        <dt class="label">お名前<span class="require">必須</span></dt>
        <dd>
          <div class="inputbox" v-bind:class="{ deletearea: inputdata }">
            <input
              type="text"
              name="namedata"
              placeholder="お名前"
              class="inputText namearea"
              v-model="namearea"
            />
          </div>
          <div class="phase_check_name" v-bind:class="{ deletearea: makesure }">
            {{ namearea }}
          </div>
          <p class="errorbox">{{ namemiss }}</p>
        </dd>
      </dl>
      <dl>
        <dt class="label">お問い合わせ内容<span class="require">必須</span></dt>
        <dd>
          <div class="inputbox" v-bind:class="{ deletearea: inputdata }">
            <textarea
              name="textboxdata"
              class="textboxdata textboxarea"
              v-model="textboxarea"
              placeholder="お問い合わせ内容をご入力ください。"
            ></textarea>
          </div>
          <div
            class="phase_check_textbox"
            v-bind:class="{ deletearea: makesure }"
          >
            {{ textboxarea }}
          </div>
          <p class="errorbox">{{ textmiss }}</p>
        </dd>
      </dl>
      <div class="buttons">
        <template v-if="!inputdata">
          <p class="privacy">
            個人情報の取り扱いについては、当サイト
            <router-link to="/privacy-policy" v-on:click="closeform">
              プライバシーポリシー</router-link
            >をご確認ください。
          </p>
          <button
            v-if="!checkmail | !checkname | !checktext"
            class="btnStyle checkng"
          >
            送信確認
          </button>
          <button
            v-if="checkmail && checkname && checktext"
            class="btnStyle check"
            v-on:click="clickbtn"
          >
            送信確認
          </button>
        </template>
        <template v-if="!makesure">
          <button class="btnStyle return" v-on:click="clickbtn_back">
            入力画面に戻る
          </button>
          <button class="btnStyle submit" v-on:click="clickbtn_send">
            メッセージを送信
          </button>
        </template>
      </div>
    </div>
    <div class="complete_screen" v-if="complete">
      <img src="@/assets/img/sendmail.svg" alt="send" />
      <h5>メッセージは正常に送信されました。</h5>
      <p>
        入力いただいたメールアドレス宛に受付完了メールを送信いたしました。<br />
        ご連絡内容に対して、3営業日以内にご返信します。
        緊急の場合や添付ファイルの送信を希望される方は<a
          href="mailto:mail@witchkraft0035.com"
          >直通メール</a
        >までご連絡ください。
      </p>
      <div class="buttons">
        <button class="btnStyle complete" v-on:click="clickbtn_again">
          別のメッセージを送信する
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.message_contents {
  width: 100%;
  padding: 0 1rem;
  padding-top: 1rem;
  position: relative;
  z-index: 0;

  @include sp {
    border-radius: 1rem 1rem 0 0;
    background-color: white;
    padding: 2rem 1rem 2rem 1rem;
  }
  .buttons {
    margin: 2rem 0 2rem 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    .btnStyle {
      height: 45px;
      background-color: $color_red;
      font-weight: bold;
      color: white;
      text-align: center;
      border-radius: 30px;
      font-size: 1rem;
      min-width: 10rem;
      flex-grow: 0;
    }
    .privacy {
      font-size: 0.8rem;
      margin-right: auto;
      a {
        color: $color_blue;
        text-decoration: underline;
      }
    }
    .checkng {
      background-color: $fontcolor_sub;
      color: $color_line;
    }

    .return {
      background-color: white;
      border: 1px solid $linkcolor_sub;
      color: $linkcolor_sub;
    }
  }
  .complete_screen {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 50%;
      height: auto;
    }
    h5 {
      font-weight: bold;
      margin-bottom: 1rem;
    }
    p {
      font-size: 0.8rem;
      a {
        color: $color_blue;
        text-decoration: underline;
      }
    }
  }
  .loader_wrap {
    position: absolute;
    top: 100%;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    z-index: 1;

    &.is-active {
      top: 0;
      .loader {
        display: block;
        -webkit-animation: load3 1.4s infinite linear;
        animation: load3 1.4s infinite linear;
      }
    }
  }
  .loader {
    font-size: 10px;
    margin: 50px auto;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    background: #ccc;
    background: linear-gradient(to right, #ccc 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    display: none;
    transform: translateZ(0);
    z-index: 9999;
    &::before {
      width: 50%;
      height: 50%;
      background: #ccc;
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
    }
    &::after {
      background: white;
      width: 80%;
      height: 80%;
      border-radius: 50%;
      content: "";
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .label {
    display: inline-flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0rem;

    .require {
      display: inline-block;
      font-size: 0.7rem;
      color: white;
      background-color: $color_red;
      line-height: 1rem;
      padding: 0.1rem 0.25rem;
      border-radius: 0.25rem;
      margin-left: 0.5rem;
    }
  }
  .errorbox {
    color: $color_red;
    font-size: 0.8rem;
    font-weight: bold;
  }
  .inputbox {
    position: relative;
    margin-bottom: 0.5rem;

    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 0;
      background-color: $color_blue;
      left: 0;
      bottom: 0;
      display: block;
      transition: 0.2s;
    }
    &:focus-within {
      &::after {
        width: 100%;
      }
    }

    input,
    textarea {
      display: block;
      padding: 0.8em 0;
      width: 100%;
      border-bottom: 1px solid $color_line;
      outline: none;
      position: relative;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;

      &:focus {
        outline: none;
      }
    }
    textarea {
      resize: vertical;
      max-height: 10em;
      min-height: 10em;
    }
  }
  .phase_check_mail,
  .phase_check_name {
    font-family: Arial, Helvetica, sans-serif;
    padding: 0.8em 0;
    width: 100%;
    border-bottom: 1px solid $color_line;
    position: relative;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 0.5em;
    z-index: 0;
  }

  .phase_check_textbox {
    padding: 0.8em 0;
    width: 100%;
    border-bottom: 1px solid $color_line;
    position: relative;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    max-height: 10em;
    min-height: 10em;
    overflow-y: scroll;
    margin-bottom: 0.5em;
    z-index: 0;
  }
}

.deletearea {
  position: absolute !important;
  left: -9999px;
  visibility: hidden;
}
</style>
<script>
import axios from "axios";

export default {
  data() {
    return {
      mailarea: "",
      namearea: "",
      textboxarea: "",
      inputdata: false,
      makesure: true,
      complete: false,
      mailmiss: "",
      namemiss: "",
      textmiss: "",
      checkmail: false,
      checkname: false,
      checktext: false,
      sending: false,
      formopen: false,
    };
  },
  emits: ["formopenstate"],
  computed: {},
  watch: {
    mailarea: function () {
      let maildata = this.mailarea;
      if (!maildata) {
        this.mailmiss = "";
      } else if (maildata.match(/.+@.+\..+/) == null) {
        this.mailmiss = "● メールアドレスの形式を確認してください";
        this.checkmail = false;
        console.log("mail:" + this.checkmail);
      } else {
        this.mailmiss = "";
        this.checkmail = true;
        console.log("mail:" + this.checkmail);
      }
    },
    namearea: function () {
      let namedata = this.namearea;
      if (!namedata) {
        this.namemiss = "● お名前を入力してください";
        this.checkname = false;
        console.log("name:" + this.checkname);
      } else {
        this.namemiss = "";
        this.checkname = true;
        console.log("name:" + this.checkname);
      }
    },
    textboxarea: function () {
      let textdata = this.textboxarea;
      if (!textdata) {
        this.textmiss = "● お問い合わせ内容を入力してください";
        this.checktext = false;
        console.log("text:" + this.checktext);
      } else {
        this.textmiss = "";
        this.checktext = true;
        console.log("text:" + this.checktext);
      }
    },
  },
  methods: {
    closeform() {
      this.$emit("formopenstate", false);
    },
    clickbtn() {
      this.inputdata = true;
      this.makesure = false;
    },
    clickbtn_back() {
      this.inputdata = false;
      this.makesure = true;
    },
    clickbtn_again() {
      this.inputdata = false;
      this.makesure = true;
      this.complete = false;
    },
    //送信ボタンを押した時の処理
    clickbtn_send: async function () {
      if (this.checkmail && this.checkname && this.checktext) {
        this.sending = true;
        let params = new URLSearchParams();
        params.append("mailarea", this.mailarea);
        params.append("namearea", this.namearea);
        params.append("textboxarea", this.textboxarea);
        //axios
        await axios
          .post("https://witchkraft0035.com/api/mail.php", params)
          .then(function (response) {
            console.log(response.data);
          })
          .catch(function (error) {
            //何かエラーが起きたら
            console.log(error);
          });
        this.complete = true;
        this.sending = false;
      }
    },
  },
};
</script>
