<template>
  <section>
    <div class="fp_service_img">
      <img src="@/assets/img/service.svg" alt="amakusa" />
    </div>
    <FpContentsTemplate>
      <template v-slot:h2>SERVICE</template>
      <template v-slot:h3
        ><span>『ホームページ屋』でも</span><span>『チラシ屋』でもない。</span>
      </template>
      <template v-slot:contents>
        情熱的な仕事は、決して型にはまらないはずだ。<br />
        人生をかけて始めた新しいビジネスのパートナーが、ただの『ホームページ制作』『チラシ制作』で果たしていいのだろうか。テンプレートを利用した、間に合わせのようなものでいいのだろうか。
        <br />人それぞれに最適なものをオーダーメイドで用意することは、私たちの約束であり、信念です。
        <br />皆様の情熱に真摯に向き合い、確かな知識と技術をもって大きな価値を提供します。
      </template>
    </FpContentsTemplate>
    <PageLink url="service"
      ><template v-slot:text>サービス内容</template></PageLink
    >
  </section>
</template>
<script>
import FpContentsTemplate from "@/components/Fp_ContentsTemplate.vue";
import PageLink from "@/components/Fp_pageLink.vue";
import gsap from "gsap";
export default {
  components: {
    FpContentsTemplate,
    PageLink,
  },
  mounted() {
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      gsap.from(".fp_service_img", {
        opacity: 0,
        xPercent: 100,
        yPercent: -10,
        ease: "expo.out",
        duration: 1.5,
        scrollTrigger: {
          trigger: ".fp_service_img",
          start: "top 80%",
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
section {
  @include section;
  position: relative;
  z-index: 0;
  .fp_service_img {
    position: absolute;
    right: 5%;
    top: -10%;
    width: 45%;
    z-index: -1;
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
