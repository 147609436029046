<template>
  <div class="pageLink">
    <router-link :to="url"> <slot name="text">リンク先名称</slot></router-link>
  </div>
</template>
<script>
export default {
  props: { url: { type: String, defailt: "" } },
};
</script>
<style scoped lang="scss">
.pageLink {
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
  a {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    font-weight: 600;
    width: 60%;
    min-width: 240px;
    border-bottom: 1px solid $fontcolor;
    padding: 1em 2em;
    display: inline-block;
    text-align: right;
    position: relative;
    line-height: 1em;
    &::after {
      content: "";
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      background-size: contain;
      background-image: url(~@/assets/ico/ico_arrow.svg);
      background-repeat: no-repeat;
    }
  }
}
</style>
