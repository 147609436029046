const worklist = {
  crazyjapan: {
    id: "crazyjapan",
    year: "2023",
    name: "CRAZY JAPAN",
    category: "LOGO CI/VI",
    thumbnail: ["thumb1.jpg", "thumb2.jpg", "thumb3.jpg"],
    credit: ["CL:Crazy Japan co.,Ltd.", "D:Yuichiro Osako"],
    desc: "EC / STORE",
    outline: [
      "海外向けオンラインショッピング、CRAZY JAPAN様のロゴを制作しました。",
      "円弧をベースとしたシルエットでインコのシンボルを作成し、オリジナルフォントと合わせて構成しました。細部のバランス調整や全体の比率設計を綿密に行い、安定感ある洗練されたシンボルを目指しました。",
    ],
    contents:
      '<div class="image_full">' +
      '<img src="../images/works/crazyjapan/img1.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="">' +
      '<img src="../images/works/crazyjapan/meishi.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="">' +
      '<img src="../images/works/crazyjapan/img2.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="">' +
      '<img src="../images/works/crazyjapan/img4.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/crazyjapan/img3.jpg" loading="lazy"/></div>',
  },
  tenseikaku: {
    id: "tenseikaku",
    year: "2022",
    name: "TENSEIKAKU",
    category: "WEB",
    thumbnail: ["thumb1.jpg", "thumb2.jpg", "thumb3.jpg"],
    credit: [
      "CL:Nagai Trading co.,Ltd.",
      "AD/DEV:Yuichiro Osako",
      "THX:嶋田土地家屋調査士事務所",
    ],
    desc: "Funeral Hall / Undertaker",
    outline: [
      "熊本県天草市『天聖閣』様のHPを制作しました。",
      "高速動作のVue3をベースに、A-Frame.jsによる360°パノラマ写真の導入や高性能なスライダーの導入により、よりわかりやすく、より充実したコンテンツを配信できるようにしています。",
    ],
    contents:
      '<div class="image_full">' +
      '<img src="../images/works/tenseikaku/img2.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="スマートフォン表示">' +
      '<img src="../images/works/tenseikaku/webs.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="pc表示">' +
      '<img src="../images/works/tenseikaku/webpc.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="pc表示">' +
      '<video controls playsinline muted preload="none" poster="../images/works/tenseikaku/placeholder.jpg" loading="lazy"/><source src="../images/works/tenseikaku/webpc.mp4" type="video/mp4"></video></div>' +
      '<div class="image" data-text="">' +
      '<img src="../images/works/tenseikaku/img1.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/tenseikaku/img3.jpg" loading="lazy"/></div>',
  },
  bcard2022: {
    id: "bcard2022",
    year: "-2022",
    name: "BUSINESS CARDS -2022",
    category: "PRINT",
    thumbnail: ["thumb1.jpg", "thumb2.jpg", "thumb3.jpg"],
    credit: [("CL:Various clients.", "AD:Yuichiro Osako")],
    desc: "Various",
    outline: [
      "2022年までに制作した名刺のうち、一部をピックアップしてご紹介します。",
      "丁寧にヒアリングを行い、企業イメージを正確に伝えるようなデザインを常に心がけています。",
    ],
    contents:
      '<div class="image" data-text="ECL inc.">' +
      '<img src="../images/works/bcard2022/img1.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="LOCKED / OneTap">' +
      '<img src="../images/works/bcard2022/img2.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="PIESSA">' +
      '<img src="../images/works/bcard2022/img3.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="NBA inc.">' +
      '<img src="../images/works/bcard2022/img4.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="三雲法務事務所">' +
      '<img src="../images/works/bcard2022/img5.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="REVOLTE / ae music japan">' +
      '<img src="../images/works/bcard2022/img6.jpg" loading="lazy"/></div>',
  },
  otentomaru: {
    id: "otentomaru",
    year: "2022",
    name: "OTENTOMARU INC.",
    category: "WEB",
    thumbnail: ["thumb1.jpg", "thumb2.jpg", "thumb3.jpg"],
    credit: ["CL:Otentomaru Inc.", "AD:Yuichiro Osako"],
    desc: "Production",
    outline: [
      "『株式会社おてんと丸』様のHPをリニューアルしました。",
      "高速動作のVue3をベースに構築し、ページ内でのアニメーションやトランジションを駆使したグラフィカルなサイトを制作しました。",
    ],
    contents:
      '<div class="image" data-text="スマートフォン表示">' +
      '<img src="../images/works/otentomaru/webs.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="pc表示">' +
      '<video  controls playsinline muted preload="none" poster="../images/works/otentomaru/placeholder.jpg" loading="lazy"/><source src="../images/works/otentomaru/webpc.mp4" type="video/mp4"></video></div>' +
      '<div class="image">' +
      '<img src="../images/works/otentomaru/img1.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/otentomaru/img2.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/otentomaru/img3.jpg" loading="lazy"/></div>',
  },
  nagaihome: {
    id: "nagaihome",
    year: "2022",
    name: "NAGAIHOME",
    category: "WEB / Photo",
    thumbnail: ["thumb1.jpg", "thumb2.jpg", "thumb3.jpg"],
    credit: [
      "CL:Nagai Trading co.,Ltd.",
      "AD/DEV:Yuichiro Osako",
      "THX:嶋田土地家屋調査士事務所",
    ],
    desc: "Real Estate / Housemaker",
    outline: [
      "熊本県天草市『長井商事株式会社不動産部』様のHPをリニューアルしました。",
      "高速動作のVue3をベースに構築し、A-Frame.jsによる360°パノラマ写真の導入や高性能なスライダーの導入により、よりわかりやすく、より充実したコンテンツを配信できるようにしています。",
      "また、制作実績内の一部写真の撮影も担当しております。",
    ],
    contents:
      '<div class="image_full">' +
      '<img src="../images/works/nagaihome/img3.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="スマートフォン表示">' +
      '<img src="../images/works/nagaihome/webs.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="pc表示">' +
      '<img src="../images/works/nagaihome/webpc.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<video  controls playsinline muted preload="none" poster="../images/works/nagaihome/placeholder.jpg" loading="lazy"/><source src="../images/works/nagaihome/webpc.mp4" type="video/mp4"></video></div>' +
      '<div class="image" data-text="撮影イメージ">' +
      '<img src="../images/works/nagaihome/img1.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="撮影イメージ">' +
      '<img src="../images/works/nagaihome/img2.jpg" loading="lazy"/></div>',
  },
  horizon: {
    id: "horizon",
    year: "2022",
    name: "HORIZON",
    category: "CI/VI",
    thumbnail: ["thumb1.jpg"],
    credit: ["CL:HORIZON", "AD:Yuichiro Osako"],
    desc: "Photographic Equipment EC",
    outline: [
      "撮影機材を販売するEC、HORIZONのロゴマークの制作を担当いたしました。",
      "シンプルでソリッドなシルエットで、高級感と信頼性の高さを表現しています",
    ],
    contents:
      '<div class="image" data-text="ロゴデザイン">' +
      '<img src="../images/works/horizon/meishi.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="ロゴデザイン">' +
      '<img src="../images/works/horizon/img1.jpg" loading="lazy"/></div>',
  },
  apothecary: {
    id: "apothecary",
    year: "2019",
    name: "Apothecary HAYASHI",
    category: "CI/VI/SIGN/WEB/PRINT",
    thumbnail: ["thumb1.jpg", "thumb2.jpg", "thumb3.jpg"],
    credit: [
      "CL:Apothecary Hayashi co.,Ltd.",
      "AD:Yuichiro Osako",
      "THX:M's Co.,Ltd / Yoshida Sign",
    ],
    desc: "Apothecary / Pharmacy / Drugstore / Essential Oil Store",
    outline: [
      "熊本県天草市の薬局『はやし薬局』様の新規開店に伴い、ロゴマークをはじめとした各種ビジュアルとWEBサイトの制作を担当いたしました。",
      "薬剤師が健康の悩みを聞き、必要な薬草を与えるというヨーロッパでのルーツにならい、地域の「かかりつけ薬局」として人々の健康のアドバイザーとなる。そんなビジョンを表現するべく、飾らずシンプルな佇まいに優しさを感じられるビジュアルを目指しました。",
    ],
    contents:
      '<div class="image_full">' +
      '<img src="../images/works/apothecary/img3.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="ロゴデザイン">' +
      '<img src="../images/works/apothecary/img1.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="名刺デザイン">' +
      '<img src="../images/works/apothecary/meishi.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="店舗サイン/ウインドウ">' +
      '<img src="../images/works/apothecary/img4.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="折り込みチラシ/開店チラシ">' +
      '<img src="../images/works/apothecary/paper.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="スマートフォン表示">' +
      '<img src="../images/works/apothecary/webs.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="pc表示">' +
      '<img src="../images/works/apothecary/webpc.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="アロマスプレーラベル">' +
      '<img src="../images/works/apothecary/img2.jpg" loading="lazy"/></div>',
  },
  bigdream: {
    id: "bigdream",
    year: "2019",
    name: "BIGDREAM",
    category: "CI/VI/SIGN/PRINT/GOODS/PHOTO",
    thumbnail: ["thumb1.jpg", "thumb2.jpg", "thumb3.jpg"],
    credit: ["CL:BIGDREAM", "AD/D/Ph:Yuichiro Osako", "THX:Yoshida Sign"],
    desc: "Burgerstand / Food truck / Kitchen car",
    outline: [
      "熊本県天草市のバーガースタンド、BIGDREAM様のCI再設計と各種ビジュアルとキッチンカーのラッピングデザイン、メニュー写真の撮影を担当いたしました。",
      "従来の赤主体のレトロアメリカンなデザインから、都会的なストリートスタイルのデザインに変更。よりブランドイメージを高めるために、ロゴデザインはシンプルさを重視した設計にし、ウェアやグッズの展開も行いました。",
    ],
    contents:
      '<div class="image_full">' +
      '<img src="../images/works/bigdream/img3.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="ロゴデザイン">' +
      '<img src="../images/works/bigdream/img1.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="名刺デザイン">' +
      '<img src="../images/works/bigdream/meishi.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/bigdream/img5.jpg" loading="lazy"/></div>' +
      '<div class="image" data-text="キッチンカーラッピング">' +
      '<img src="../images/works/bigdream/car.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/bigdream/img4.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/bigdream/flag.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/bigdream/tshirt.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/bigdream/paper.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/bigdream/menu.jpg" loading="lazy"/></div>',
  },
  naganolaw: {
    id: "naganolaw",
    year: "2020",
    name: "NAGANO KUNISUKE LAW OFFICE",
    category: "CI/VI/PRINT",
    thumbnail: ["thumb1.jpg", "thumb2.jpg", "thumb3.jpg"],
    credit: ["CL:Nagano kunisuke law office", "D:Yuichiro Osako"],
    desc: "Law office / Lawyer / Legal proffession",
    outline: [
      "長野国助法律事務所様が開業100周年を迎えるにあたり、ロゴデザインを刷新。現代風のイメージに合わせつつも、従来のビジュアルをマッチするように再設計。ニュースレターやリーフレットの作成まで行いました。",
    ],
    contents:
      '<div class="image" data-text="ロゴデザイン">' +
      '<img src="../images/works/naganolaw/img1.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/naganolaw/img2.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/naganolaw/img3.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/naganolaw/img4.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/naganolaw/img5.jpg" loading="lazy"/></div>',
  },
  amakusafood: {
    id: "amakusafood",
    year: "2021",
    name: "amakusafood",
    category: "WEB DESIGN/DEV",
    thumbnail: ["thumb1.jpg", "thumb2.jpg", "thumb3.jpg"],
    credit: [
      "CL:天草宝島物産公社",
      "PROD:Otentomaru inc",
      "D/Dev:Yuichiro Osako",
      "Ph:Goto Aki",
      "Wr:Kazumi Serizawa",
    ],
    desc: "Promotion / Food / Regional specialty",
    outline: [
      "「天草産品の背景を、皆様にお伝えしたい」をキーワードに、生産者たちのありのままの景色を映し出すことをコンセプトとしたプロモーションページを作成いたしました。",
      "装飾は必要最低限に、ライター芹澤和美氏の言葉とGOTO AKI氏の写真から伝わる雰囲気を十分に引き立てるため、自然と読み進めたくなるようなアニメーション展開と、画像をゆっくりと揺らすことに寄って没入感、ライブ感を伝えられる構成としながら、軽量な動作のための遅延読み込みなど、シンプルなレイアウトに機能的な要素を取り入れています。",
    ],
    contents:
      '<div class="image_full">' +
      '<img src="../images/works/amakusafood/img3.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/amakusafood/img1.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<video  controls playsinline muted preload="none" poster="../images/works/amakusafood/placeholder.jpg" loading="lazy"/><source src="../images/works/amakusafood/webpc.webm" type="video/webm"><source src="../images/works/amakusafood/webpc.mp4" type="video/mp4"></video></div>' +
      '<div class="image">' +
      '<img src="../images/works/amakusafood/websp.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/amakusafood/webpc.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/amakusafood/img4.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/amakusafood/img5.jpg" loading="lazy"/></div>',
  },
  scratch: {
    id: "scratch",
    year: "2021",
    name: "Scratch co.,Ltd",
    category: "CI/CARD/WEB DESIGN/DEV",
    thumbnail: ["thumb1.jpg", "thumb2.jpg", "thumb3.jpg"],
    credit: ["CL:Scratch co.,Ltd", "AD/Dev:Yuichiro Osako"],
    desc: "Spatial design / Event venue / Interior work",
    outline: [
      "イベント会場のデザイン、設計施工を主に行っている株式会社スクラッチ様のロゴデザインおよびWEBサイトの制作を行いました。",
      "時代に爪跡を残すというキャッチコピーから、斜めのレイアウトを活かした構成、モノクロを主体としたカラー設計としています。",
    ],
    contents:
      '<div class="image_full">' +
      '<img src="../images/works/scratch/img2.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/scratch/img1.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/scratch/meishi.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<video  controls playsinline muted preload="none" poster="../images/works/scratch/placeholder.jpg" loading="lazy"/><source src="../images/works/scratch/webpc.mp4" type="video/mp4"></video></div>' +
      '<div class="image">' +
      '<img src="../images/works/scratch/websp.jpg" loading="lazy"/></div>' +
      '<div class="image">' +
      '<img src="../images/works/scratch/webpc.jpg" loading="lazy"/></div>' +
      '<div class="image">',
  },
};
for (let key in worklist) {
  worklist[key].path = "/works/" + key;
  worklist[key].imgpath = "./images/works/" + key + "/main.jpg";
  worklist[key].imgpath2 = "/images/works/" + key + "/main.jpg";
  for (let thumbs in worklist[key].thumbnail) {
    worklist[key].thumbnail[thumbs] =
      "./images/works/" + key + "/" + worklist[key].thumbnail[thumbs];
  }
}

export default worklist;
