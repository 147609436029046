<template>
  <section>
    <div class="fp_about_img">
      <img src="@/assets/img/amakusa.svg" alt="amakusa" />
    </div>
    <FpContentsTemplate>
      <template v-slot:h2>ABOUT US</template>
      <template v-slot:h3
        ><span>優れたデザインが、</span><span>次の時代をつくる。</span>
      </template>
      <template v-slot:contents>
        デザインの本質は見た目の美しさではない。意思のないデザインは、意味をなさない。<br />だから私たちは考え、そして整理し、より良いものを生み出すべくものづくりにあたります。<br />
        ひとつのロゴが、ひとつの名刺が、仕事すべてのイメージを革新していく。
        やがて新たなイメージを軸に、様々なアイデアが生まれていく。<br />
        そんなデザインを、ともに作り上げていきましょう。
        <br />
        熊本県天草市という日本の片隅から、次の時代の1ページを、あなたとともに。
      </template>
    </FpContentsTemplate>
    <PageLink url="about"
      ><template v-slot:text>私たちについて</template></PageLink
    >
  </section>
</template>
<script>
import FpContentsTemplate from "@/components/Fp_ContentsTemplate.vue";
import PageLink from "@/components/Fp_pageLink.vue";
import gsap from "gsap";
export default {
  components: {
    FpContentsTemplate,
    PageLink,
  },
  mounted() {
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      gsap.from(".fp_about_img", {
        opacity: 0,
        xPercent: 100,
        yPercent: -10,
        ease: "expo.out",
        duration: 1.5,
        scrollTrigger: {
          trigger: ".fp_about_img",
          start: "top 80%",
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
section {
  @include section;
  position: relative;
  z-index: 0;
  .fp_about_img {
    position: absolute;
    right: -1%;
    top: -10%;
    width: 50%;
    z-index: -1;
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
