<template>
  <h2><slot name="h2">TITLE</slot></h2>
  <h3>
    <slot name="h3">TEXT</slot>
  </h3>
  <p>
    <slot name="contents">textcontents</slot>
  </p>
</template>
<style lang="scss" scoped>
h2 {
  font-family: $headline;
  font-weight: 600;
  font-size: clamp(2rem, 4vw, 4rem);
  line-height: 1em;
  margin-bottom: 2em;
}
h3 {
  font-weight: 600;
  font-size: clamp(1.5rem, 2vw, 2.5rem);
  line-height: 1.5em;
  margin-bottom: 1em;
  span {
    display: inline-block;
    white-space: pre-wrap;
  }
}
p {
  font-weight: 500;
  max-width: 900px;
  line-height: 2;
  margin-bottom: $hr_padding;
  span {
    display: inline-block;
    white-space: pre-wrap;
  }
}
</style>
