<template>
  <Introduction></Introduction>
  <Fpworks></Fpworks>
  <About></About>
  <Service></Service>
  <Footer></Footer>
</template>
<script>
import Introduction from "@/components/Fp_introduction.vue";
import Fpworks from "@/components/Fp_gallery.vue";
import About from "@/components/Fp_about.vue";
import Service from "@/components/Fp_service.vue";
import Footer from "@/components/Part_Footer.vue";
export default {
  components: {
    Introduction,
    About,
    Service,
    Fpworks,
    Footer,
  },
};
</script>
