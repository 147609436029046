/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import home from "@/views/cp_frontpage.vue";
import NotFound from "@/views/pages_404.vue";
import Worksitem from "@/views/cp_works_detail.vue";
import workslist from "@/js/worklist.js";

const routes = [
  {
    path: "/",
    name: "HOME",
    component: home,
    meta: { title: "天草でデザインを依頼するなら" },
  },
  {
    path: "/works",
    name: "WORKS",
    component: () => import("../views/cp_works.vue"),
    meta: { title: "ALL WORKS" },
  },

  {
    path: "/about",
    name: "ABOUT",
    component: () => import("../views/cp_about.vue"),
    meta: { title: "ABOUT" },
  },
  {
    path: "/service",
    name: "SERVICE",
    component: () => import("../views/cp_service.vue"),
    meta: { title: "SERVICE" },
  },

  {
    path: "/works/:id",
    component: Worksitem,
    name: "WorksDetail",
    props: true,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../views/view_privacy.vue"),
    meta: { title: "プライバシーポリシー" },
  },
  {
    path: "/404",
    name: "NotFoundError",
    component: NotFound,
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior(to, from, savedPosition) {
    let Position = Object;
    return new Promise((resolve, reject) => {
      if (savedPosition) {
        Position = savedPosition;
      } else {
        Position = { left: 0, top: 0 };
      }
      setTimeout(() => {
        resolve(Position);
      }, 500);
    });
  },
});
router.afterEach((to, from) => {
  let setTitle = "WITCHKRAFT GRAPHIC DESIGN";
  let ogTitle = "WITCHKRAFT GRAPHIC DESIGN";
  let setDesc =
    "ウィッチクラフト グラフィックデザインは、熊本県天草市を拠点に活動するデザイナー、大迫雄一朗によるデザインオフィスです。";
  if (to.params.id) {
    setTitle = workslist[to.params.id].name + " | WITCHKRAFT GRAPHIC DESIGN";
    ogTitle = workslist[to.params.id].name;
    setDesc = workslist[to.params.id].outline.join("");
  } else if (to.meta.title) {
    setTitle = to.meta.title + " | WITCHKRAFT GRAPHIC DESIGN";
  }
  document.title = setTitle;
  document
    .querySelector("meta[name='description']")
    .setAttribute("content", setDesc);
});
export default router;
