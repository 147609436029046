<template>
  <section>
    <div class="gallery_item" v-for="(data, index) in limitCount" :key="index">
      <router-link :to="data.path" class="gallery_img">
        <img ref="thumbnail" :src="data.imgpath" />
        <img
          loading="lazy"
          ref="thumbnail"
          v-for="tb in data.thumbnail"
          :key="tb"
          :src="tb"
        />
      </router-link>
      <router-link :to="data.path" class="gallery_caption">
        <p class="year">{{ data.year }}</p>
        <h3 class="title">{{ data.name }}</h3>
        <h4 class="category">{{ data.category }}</h4>
        <svg
          version="1.1"
          id="arrow"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 30 30"
        >
          <polygon
            class="st0"
            points="0,30 0,1.8 3.1,1.8 3.1,24.7 27.8,0 30,2.2 5.3,26.9 28.2,26.9 28.2,30 "
          />
        </svg>
      </router-link>
    </div>
    <div class="hr_padding">
      <PageLink url="/works"
        ><template v-slot:text>すべての制作実績</template></PageLink
      >
    </div>
  </section>
</template>

<script>
import PageLink from "@/components/Fp_pageLink.vue";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
export default {
  data() {
    return {
      inviews: ".image img",
      thumbs: ".gallery_img",
      list: Array,
    };
  },
  components: {
    PageLink,
  },
  computed: {
    limitCount() {
      let numberofContents;
      let list;
      list = Object.values(this.$workslist).slice(0, numberofContents);
      return list;
    },
  },
  mounted() {
    this.scrollAnimation();
    // this.loadedAnimation();
    this.$nextTick(() => {
      setTimeout(function () {
        ScrollTrigger.refresh();
      }, 0);
    });
  },
  methods: {
    loadedAnimation() {},
    scrollAnimation() {
      gsap.utils.toArray(this.inviews).forEach((inview) => {
        gsap.fromTo(
          inview,
          {
            opacity: 0,
            scale: 1.1,
          },
          {
            opacity: 1,
            scale: 1,
            duration: 1,
            scrollTrigger: {
              trigger: inview,
              start: "top 80%",
            },
          }
        );
      });
      gsap.utils.toArray(".gallery_img").forEach((thumbs) => {
        let thumb = gsap.utils.toArray(thumbs.children);

        thumb.forEach((item, index) => {
          let Speed = 0.48;
          let tl = gsap
            .timeline({
              id: "thumbAnimation",
              paused: true,
              delay: index * Speed,
              repeat: -1,
              repeatDelay: 0,
            })
            .set(item, { zIndex: thumb.length - index })
            .fromTo(
              item,
              { zIndex: thumb.length - index },
              {
                zIndex: 0,
              },
              Speed
            )
            .to(
              item,
              { zIndex: -1, duration: Speed * (thumb.length - 2) },
              Speed * (thumb.length - 2)
            );
          ScrollTrigger.create({
            trigger: thumb,
            start: "top 50%",
            end: "bottom 50%",
            toggleActions: "restart reset restart reset",
            animation: tl,
          });
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
section {
  display: grid;
  grid-gap: $vt_padding;
  padding-bottom: $vt_padding;
  @include sp {
    grid-gap: 5vw;
  }
  .gallery_item {
    display: flex;
    position: relative;
    z-index: 1;
    @include sp {
      flex-direction: column;
    }
    &:hover {
      .gallery_img {
        &::after {
          width: 100%;
          margin-top: 5%;
          margin-left: 20vw;

          @include sp {
            margin: 0;
            background-image: none;
          }
        }
      }
    }

    .gallery_img {
      aspect-ratio: 16/9;
      width: 60%;
      flex-shrink: 0;
      display: block;
      z-index: 0;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color_light;
        border-radius: 1rem;
        transition: 0.5s $easing;
        z-index: -100;
        background-image: url("~@/assets/whitenoise.gif");
        opacity: 0.08;
      }
      @include sp {
        width: 100%;
        border-radius: 0;
        aspect-ratio: 3/2;
      }
      img {
        border-radius: 0 1rem 1rem 0;
        position: absolute;
        @include img100p;
        @include sp {
          border-radius: 0;
        }
      }
    }
  }
  .gallery_caption {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 3rem $hr_padding;
    overflow: hidden;
    z-index: 1;
    position: relative;
    @include sp {
      width: 100%;
      padding: 1rem $hr_padding;
    }
    #arrow {
      font-size: clamp(2rem, 3vw, 3rem);
      fill: $fontcolor;
      position: absolute;
      top: 0;
      right: 0;
      width: clamp(0.35em, 6vw, 0.75em);
      height: auto;
      margin: 3rem $hr_padding;
      @include sp {
        margin: 2rem $hr_padding;
      }
    }
    .year {
      margin-bottom: 1em;
      font-size: 1rem;
      font-weight: bold;
      @include sp {
        font-size: 0.8em;
        margin-bottom: 0.5em;
      }
    }
    .title {
      font-family: $headline;
      font-weight: 600;
      font-size: clamp(2rem, 6vw, 6rem);
      line-height: 0.8em;
      font-weight: bold;
      transition: 0.5s $easing;
      @include sp {
        margin-bottom: -0.5rem;
        padding-right: 1.5em;
      }
    }
    .category {
      font-size: 1rem;
      margin-top: 1em;
      word-break: break-all;
      font-weight: bold;
    }
    .credit {
      position: relative;
      font-size: 0.8rem;
      margin-top: 1em;
      li {
        display: inline-block;
        margin-right: 0.5em;
      }
    }
  }
}
</style>
