<template>
  <nav>
    <div
      type="button"
      id="navigation_btn"
      v-on:click="changeOpen"
      v-bind:class="{ 'is-active': openstate }"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div id="navigation" v-bind:class="{ 'is-active': openstate }">
      <div class="navigation_menu">
        <div class="col-navigation">
          <ul>
            <li>
              <router-link to="/works" v-on:click="changeOpen">
                WORKS
                <span class="workscnt">{{ this.$workscnt }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/about" v-on:click="changeOpen">
                ABOUT US
              </router-link>
            </li>
            <li>
              <router-link to="/service" v-on:click="changeOpen">
                SERVICE
              </router-link>
            </li>
            <li>
              <router-link to="/" v-on:click="changeOpen">HOME</router-link>
            </li>
          </ul>
        </div>
        <div class="col-social">
          <ul>
            <li>
              <a
                v-on:click="changeOpen"
                href="https://www.instagram.com/witchkraftgraphicdesign/"
                target="blank"
                rel="noopener"
                class="instagram"
              >
                instagram
              </a>
            </li>
            <li>
              <a
                v-on:click="changeOpen"
                href="https://www.facebook.com/WITCHKRAFT0035"
                target="blank"
                rel="noopener"
                class="facebook"
              >
                facebook
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="navigation_works">
        <NavList v-on:open="emitOpen"></NavList>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">
$menusize: 40px;
$menugap: 10px;
#navigation_btn {
  width: $menusize;
  height: $menusize;
  position: fixed;
  flex-direction: row-reverse;
  gap: $hr_padding;
  right: 0;
  top: 0;
  margin: 20px $hr_padding;
  z-index: 9999;
  &::after {
    content: "";
    display: block;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    height: $menusize;
    width: $menusize;
    z-index: -1;
    border-radius: 50%;
    opacity: 0;
    transform: scale(0);
    transition: 0.2s $bounce;
  }
  &:hover {
    &::after {
      opacity: 1;
      transform: scale(1.5);
    }
  }
  &.is-active {
    &::after {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  span {
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    display: block;
    background-color: $fontcolor;
    width: $menusize;
    height: 2px;
    transition: 0.2s;

    &:nth-child(1) {
      transform: translateY(-#{$menugap});
    }
    &:nth-child(2) {
      transform: none;
    }
    &:nth-child(3) {
      transform: translateY(#{$menugap});
    }
  }
  &.is-active {
    span {
      background-color: white;
      &:nth-child(1) {
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        transform: scaleX(0);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
}
#navigation {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  gap: $hr-padding;
  top: 0;
  right: 0;
  color: white;
  width: 100%;
  min-height: 100%;
  opacity: 0;
  transition-delay: 0.5s;
  transform: translatey(-100%);
  overflow: hidden;
  z-index: 9998;

  &::before {
    content: "";
    background-color: rgba(30, 30, 30, 0.98);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: 0.5s;
    opacity: 0;
  }
  &.is-active {
    opacity: 1;
    transform: translatex(0%);
    transition-delay: 0s;
    &::before {
      opacity: 1;
    }
    .navigation_menu {
      opacity: 1;
      transition-delay: 0s;
    }
    .navigation_works {
      transform: translatex(0%);
    }
  }

  .navigation_menu {
    padding: $vt_padding $hr_padding;
    padding-top: calc($top_padding * 0.75);
    text-align: right;
    transition: 0.2s;
    opacity: 0;

    .col-navigation {
      font-family: $headline;
      font-size: clamp(3rem, 5vw, 5rem);
      line-height: 1em;
      margin-bottom: 1em;
      .workscnt {
        font-size: 0.4em;
        margin-left: 0.2em;
        line-height: 1em;
        vertical-align: 100%;
      }
      ul {
        display: grid;
        grid-gap: 2rem;
        li a {
          display: inline-block;
          position: relative;
          padding-bottom: 0.12em;
          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: white;
            height: 0.06em;
            display: block;
            transition: 0.2s $easing;
          }
          &::before {
            right: 0;
            bottom: 0;
            width: 100%;
          }
          &::after {
            left: 0;
            bottom: 0;
            width: 0%;
            transition-delay: 0.2s;
          }
          &:hover {
            &::before {
              width: 0%;
            }
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
    .col-social {
      font-weight: bold;
      font-size: 1.5em;
      li {
        margin-bottom: 1rem;
        a {
          padding-left: 1.2em;
          position: relative;
          display: inline-flex;
          &::before {
            content: "";
            width: 0.8em;
            height: 0.8em;
            left: 0;
            top: calc(50% - 0.4em);
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            filter: invert(1);
          }

          &.facebook::before {
            background-image: url("~@/assets/ico/ico_fb.svg");
          }
          &.instagram::before {
            background-image: url("~@/assets/ico/ico_ig.svg");
          }
        }
      }
    }
  }
  .navigation_works {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    padding: 0 $hr_padding;
    width: clamp(120px, 40vw, 640px);
    margin-right: auto;
    transform: translateX(-100%);
    transition: 0.2s $easing;
    transition-delay: 0s;
    word-break: break-word;
    overflow-wrap: break-word;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
<script>
import NavList from "../components/Navigation_list.vue";
export default {
  data() {
    return {
      open: false,
    };
  },
  components: {
    NavList,
  },
  props: ["openstate"],
  emits: ["open"],
  methods: {
    changeOpen() {
      this.open = !this.openstate;
      this.$emit("open", this.open);
    },
    emitOpen(open) {
      this.open = open;
      this.$emit("open", this.open);
    },
  },
  mounted() {},
};
</script>
