<template>
  <div class="detail_header_title">
    <p class="year">{{ workslist[id].year }}</p>
    <div class="title">
      <h2>{{ workslist[id].name }}</h2>
      <svg
        version="1.1"
        id="arrow"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
      >
        <polygon
          class="st0"
          points="0,30 0,1.8 3.1,1.8 3.1,24.7 27.8,0 30,2.2 5.3,26.9 28.2,26.9 28.2,30 "
        />
      </svg>
    </div>

    <p class="desc">{{ workslist[id].desc }}</p>
  </div>
  <div class="detail_header_img">
    <img :src="'.' + workslist[id].imgpath" :alt="workslist[id].name" />
  </div>
  <div class="detail_outline">
    <div class="detail_outline_left">
      <div class="detail_role">
        <h3>ROLE</h3>
        <p>{{ workslist[id].category }}</p>
      </div>
      <div class="detail_credits">
        <h3>CREDITS</h3>
        <ul>
          <li v-for="crd in workslist[id].credit" :key="crd">
            {{ crd }}
          </li>
        </ul>
      </div>
    </div>
    <div class="detail_outline_right">
      <h3>OUTLINE</h3>
      <p v-for="ol in workslist[id].outline" :key="ol">
        {{ ol }}
      </p>
    </div>
  </div>
  <article id="detail_content">
    <div class="detail_inner" v-html="workslist[id].contents"></div>
  </article>
  <Nextwork :id="id"></Nextwork>
  <!--  -->
  <Footer></Footer>
</template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Footer from "@/components/Part_Footer.vue";
import Nextwork from "@/components/Part_NextworkButton.vue";
export default {
  props: ["id"],

  data() {
    return {
      workslist: this.$workslist,
      inviews: ".image img",
      list: Array,
    };
  },
  components: {
    Nextwork,
    Footer,
  },
  mounted() {
    this.scrollAnimation();
    this.loadedAnimation();
    this.$nextTick(() => {
      setTimeout(function () {
        ScrollTrigger.refresh();
      }, 0);
    });
  },
  methods: {
    loadedAnimation() {
      gsap.from(".detail_header_img img", {
        scale: 1.5,
        duration: 3,
        ease: "power4.out",
        onComplete: function () {
          ScrollTrigger.refresh();
        },
      });
    },
    scrollAnimation() {
      gsap.to(".detail_header_img img", {
        yPercent: 35,
        scrollTrigger: {
          trigger: ".detail_header_img",
          start: "top 5%",
          end: "bottom",
          scrub: true,
        },
      });
      gsap.to(".detail_header_img", {
        width: "90%",
        "border-radius": "1rem",
        duration: 0.5,
        ease: "expo.inOut",
        scrollTrigger: {
          trigger: "body",
          start: "top -5%",
          toggleActions: "play none none reverse",
        },
      });
      gsap.from(".detail_outline", {
        opacity: 0,
        scrollTrigger: {
          trigger: ".detail_outline",
          start: "top 80%",
          end: "bottom",
        },
      });
      gsap.utils.toArray(this.inviews).forEach((inview) => {
        gsap.fromTo(
          inview,
          {
            opacity: 0,
            scale: 1.1,
          },
          {
            opacity: 1,
            scale: 1,
            duration: 1,
            scrollTrigger: {
              trigger: inview,
              start: "top 80%",
            },
          }
        );
      });
    },
  },
};
</script>

<style lang="scss">
.detail_header_img {
  position: relative;
  width: 100%;
  height: 80vh;
  z-index: 0;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #eee;
  @include sp {
    height: 55vh;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.detail_header_title {
  background-color: white;
  margin: 0 auto;
  padding: $top_padding $hr_padding 4rem $hr_padding;
  position: relative;
  z-index: 0;
  min-height: 35vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @include sp {
    min-height: 0;
  }

  .title {
    position: relative;
    display: inline-flex;
    font-size: clamp(4rem, 9vw, 8rem);
    h2 {
      font-family: $headline;
      font-weight: 600;
      line-height: 0.8;
      max-width: 60%;
      margin-bottom: 0.25em;

      @include sp {
        max-width: 75%;
      }
    }
    #arrow {
      fill: $fontcolor;
      height: clamp(0.35em, 6vw, 0.75em);
      margin-top: 0.02em;
      width: auto;
      margin-left: auto;
    }
  }

  .year {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    font-weight: 600;
    margin-bottom: 1em;
  }
  .desc {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    font-weight: 600;
    line-height: 1.2em;
  }
}
.detail_outline {
  padding: 4rem $hr_padding;
  display: flex;
  max-width: 960px;
  box-sizing: content-box;
  margin: 0 auto;
  gap: 4rem;
  @include sp {
    display: block;
  }
  h3 {
    font-weight: 600;
    margin-bottom: 1rem;
  }
  p {
    font-size: 0.8em;
    font-weight: 500;
    line-height: 2;
  }
  li {
    font-size: 0.8em;
    font-weight: 500;
    line-height: 2;
  }

  .detail_outline_left {
    flex-basis: 25%;
    @include sp {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2em;
    }
    .detail_role,
    .detail_credits {
      margin-bottom: 1.5rem;
    }
  }
  .detail_outline_right {
    flex-basis: 75%;
    flex-grow: 0;
    p {
      font-size: 1rem;
      margin-bottom: 1em;
    }
  }
}
#detail_content {
  background-color: white;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5rem;
  padding-top: 5rem;

  .outline {
    padding: 0 $hr_padding;
    margin: 0 auto;
    h3 {
      font-weight: 600;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 1em;
      line-height: 2.5em;
      max-width: 640px;
    }
  }
  .detail_inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5rem;
    .image {
      max-width: 1280px;
      height: auto;
      margin: 0 auto;
      overflow: hidden;
      padding: 0 $hr_padding;
      position: relative;
      &::after {
        content: attr(data-text);
        font-size: 0.8rem;
        position: relative;
        margin-top: 0.5em;
        font-weight: bold;
        color: $fontcolor_sub;
      }
      img {
        width: 100%;
        height: auto;
        border-radius: 1rem;
      }
      video {
        max-width: 100%;
        width: 100vw;
      }
    }
    .image_full {
      width: 100%;
      height: 65vmin;
      margin: 0 auto;
      padding: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
  }
}
</style>
